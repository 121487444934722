<template>
  <div class="navbar_wrapper container-fluid p-0 shadow">
    <b-navbar class="container-xl py-0" print>
      <b-navbar-brand class="mr-auto" :to="{name:'index'}">Wakaru.Org</b-navbar-brand>
      <LoginNavbarItem class="p-0" />
    </b-navbar>
  </div>
</template>

<script>
import LoginNavbarItem from '@/components/ui/LoginNavbarItem.vue';

export default {
  name: 'TestNavBar',
  components: { LoginNavbarItem },
};
</script>
